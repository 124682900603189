
import {Options, Vue} from 'vue-class-component';
import Location from "@/components/Location.vue";
import Annoucement from "@/components/Annoucement.vue";
import Countdown from "@/components/countdown/Countdown.vue";
import Party from "@/components/Party.vue";

@Options({
  components: {
    Party,
    Countdown,
    Annoucement, Location
  },
})
export default class Home extends Vue {
}
