import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconPosition = _resolveComponent("IconPosition")!
  const _component_LocationMapModal = _resolveComponent("LocationMapModal")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("a", {
      class: "mt-5 flex cursor-pointer underline text-gray-800",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.addressClick && _ctx.addressClick(...args)))
    }, [
      _createVNode(_component_IconPosition, {
        size: "6",
        class: "mr-2 text-gray-700"
      }),
      _createTextVNode(" Via Reynaudi, 5 - 12024 Costigliole Saluzzo (Cuneo) - Italia ")
    ]),
    _createVNode(_component_LocationMapModal, {
      show: _ctx.modalShow,
      onClose: _ctx.close
    }, null, 8, ["show", "onClose"])
  ]))
}