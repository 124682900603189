
import {Vue} from "vue-class-component";

export default class NavBar extends Vue {
  showMenu = false
  routes = [
    {name: 'Home', path: '/'},
    {name: 'Come raggiungerlo', path: '/come-raggiungerlo'},
    {name: 'Pernottamento', path: '/pernottamento'},
    // {name: 'Conferma', path: '/conferma'}
  ]

  isCurrent(path: string) {
    return path === this.$route.path
  }
}
