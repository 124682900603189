
import {Options, Vue} from "vue-class-component";

@Options({
  props: {
    size: String
  }
})
export default class Close extends Vue {
}
