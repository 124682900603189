import {createStore, createLogger, ActionContext} from 'vuex'
import rsvp from './modules/rsvp'

const debug = process.env.NODE_ENV !== 'production'

export interface State {
    loading: boolean
    errors: string[],
    messages: string[]
}

const state: State = {
    loading: false,
    errors: [],
    messages: []
}

const mutations = {
    loading(state: State, value: boolean) {
        state.loading = value
    },
    errors(state: State, value: string[]) {
        state.errors = value
    },
    messages(state: State, value: string[]) {
        state.messages = value
    }
}

const actions = {
    setMessages(context: ActionContext<State, State>, messages: string[]) {
        context.commit('messages', messages)
        setTimeout(function () {
            context.commit('messages', [])
        }, 8000)
    },
    setErrors(context: ActionContext<State, State>, messages: string[]) {
        context.commit('errors', messages)
        setTimeout(function () {
            context.commit('errors', [])
        }, 8000)
    }
}

const getters = {
    loading(state: State) {
        return state.loading
    },
    messages(state: State) {
        return state.messages
    },
    errors(state: State) {
        return state.errors
    }
}

export default createStore<State>({
    modules: {
        rsvp
    },
    state: state,
    mutations: mutations,
    actions: actions,
    strict: debug,
    getters: getters,
    plugins: debug ? [createLogger()] : []
})