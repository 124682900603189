
import {Options, Vue} from "vue-class-component";
import moment, {Moment} from 'moment';
import CountdownItem from "@/components/countdown/CountdownItem.vue";

@Options({
  components: {CountdownItem},
  created() {
    this.start()
  }
})
export default class Countdown extends Vue {
  pic1 = require('@/assets/love.jpeg')
  endDate: Moment = moment('20220722T1630')
  difference = 0

  start() {
    this.difference = moment().diff(this.endDate, 'milliseconds')
  }

}
