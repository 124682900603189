
import {Vue} from "vue-class-component";

export default class LocationImageSlider extends Vue {
  images = [
    '@/assets/castello-rosso-1.jpg',
    '@/assets/castello-rosso-2.jpg',
    '@/assets/castello-rosso-4.jpg',
    '@/assets/castello-rosso-5.jpg'
  ]
}
