
import {Options, Vue} from "vue-class-component";
import LocationMapModal from "@/components/LocationMapModal.vue";
import IconPosition from "@/components/icons/IconPosition.vue"

@Options({
  components: {LocationMapModal, IconPosition}
})
export default class LocationMapLink extends Vue {
  modalShow = false

  addressClick() {
    this.modalShow = true
  }

  close() {
    this.modalShow = false
  }
}
