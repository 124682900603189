
import {Vue} from "vue-class-component";

export default class Alert extends Vue {
  get errors() {
    return this.$store.getters['errors']
  }

  get messages(): string[] {
    return this.$store.getters['messages']
  }
}
