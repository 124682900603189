import {ActionContext} from "vuex";
import {State} from "@/store";
import axios from "axios";

export interface Intolerance {
    type: string
    name: string
    vegetarian: boolean
    celiac: boolean
    intolerance: string | null
}

export interface RsvpState {
    name: string | null
    surname: string | null
    email: string | null
    cellphone: string | null
    adults: number
    child: number
    youngChild: number
    baby: number
    guests: Intolerance[]
}

const state: RsvpState = {
    name: null,
    surname: null,
    email: null,
    cellphone: null,
    adults: 0,
    child: 0,
    youngChild: 0,
    baby: 0,
    guests: []
}

// getters
const getters = {
    adults: (state: RsvpState) => {
        return state.adults
    },
    child: (state: RsvpState) => {
        return state.child
    },
    youngChild: (state: RsvpState) => {
        return state.youngChild
    },
    baby: (state: RsvpState) => {
        return state.baby
    },
    name: (state: RsvpState) => {
        return state.name
    },
    surname: (state: RsvpState) => {
        return state.surname
    },
    email: (state: RsvpState) => {
        return state.email
    },
    cellphone: (state: RsvpState) => {
        return state.cellphone
    },
    guests(state: RsvpState): Intolerance[] {
        return state.guests
    }
}

// actions
const actions = {
    plus(context: ActionContext<RsvpState, State>, property: string) {
        let value = context.getters[property]
        value++
        context.commit(property, value)
        context.dispatch('parseGuests')
    },
    minus(context: ActionContext<RsvpState, State>, property: string) {
        let value = context.getters[property]
        if (value > 0) {
            value--
        }
        context.commit(property, value)
        context.dispatch('parseGuests')
    },
    parseGuests(context: ActionContext<RsvpState, State>) {
        const guests: Intolerance[] = []
        for (let i = 0; i < context.state.adults; i++) {
            guests.push({type: 'adults', name: '', vegetarian: false, celiac: false, intolerance: ''} as Intolerance)
        }
        for (let i = 0; i < context.state.child; i++) {
            guests.push({type: 'child', name: '', vegetarian: false, celiac: false, intolerance: ''} as Intolerance)
        }
        for (let i = 0; i < context.state.youngChild; i++) {
            guests.push({type: 'youngChild', name: '', vegetarian: false, celiac: false, intolerance: ''} as Intolerance)
        }
        for (let i = 0; i < context.state.baby; i++) {
            guests.push({type: 'baby', name: '', vegetarian: false, celiac: false, intolerance: ''} as Intolerance)
        }
        context.commit('guests', guests)
    },
    save(context: ActionContext<RsvpState, State>, token: string) {
        const body = {
            token: token,
            name: context.state.name,
            surname: context.state.surname,
            email: context.state.email,
            cellphone: context.state.cellphone,
            adults: context.state.adults,
            child: context.state.child,
            youngChild: context.state.youngChild,
            baby: context.state.baby,
            intolerances: context.state.guests.map(item => {
                return {
                    type: item.type,
                    name: item.name,
                    vegetarian: item.vegetarian,
                    celiac: item.celiac,
                    intolerance: item.intolerance
                }
            })
        }

        return axios.post(process.env.VUE_APP_API_URL + '/api/save', body)
    }
}

// mutations
const mutations = {
    name(state: RsvpState, value: string) {
        state.name = value
    },
    surname(state: RsvpState, value: string) {
        state.surname = value
    },
    email(state: RsvpState, value: string) {
        state.email = value
    },
    cellphone(state: RsvpState, value: string) {
        state.cellphone = value
    },
    adults(state: RsvpState, value: number) {
        state.adults = value
    },
    child(state: RsvpState, value: number) {
        state.child = value
    },
    youngChild(state: RsvpState, value: number) {
        state.youngChild = value
    },
    baby(state: RsvpState, value: number) {
        state.baby = value
    },
    guests(state: RsvpState, guests: Intolerance[]) {
        state.guests = guests
    },
    clear(state: RsvpState) {
        state.name = null
        state.surname = null
        state.email = null
        state.cellphone = null
        state.adults = 0
        state.child = 0
        state.youngChild = 0
        state.baby = 0
        state.guests = []
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
