import {createApp} from 'vue'
import App from './App.vue'
import './styles/main.css'
import router from './router'
import 'animate.css';
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import * as Sentry from "@sentry/vue";
import {BrowserTracing} from "@sentry/tracing";
import VueSplide from '@splidejs/vue-splide';
import '@splidejs/splide/dist/css/splide.min.css';
import store from './store'
import { VueReCaptcha } from 'vue-recaptcha-v3'
import VueCountdown from '@chenfengyuan/vue-countdown';
import {IReCaptchaOptions} from "vue-recaptcha-v3/dist/IReCaptchaOptions";

const app = createApp(App)

app.use(VueSplide)
app.use(router)
app.use(store)
app.component(VueCountdown.name, VueCountdown);
const recaptchaConfig: IReCaptchaOptions = {
    siteKey: '6Le4imIeAAAAAN3zyvU_vvsHe2509PW7CoNkia44',
    loaderOptions: {}
}
app.use(VueReCaptcha, recaptchaConfig)

app.component('font-awesome-icon', FontAwesomeIcon)

// Vue.config.productionTip = false

Sentry.init({
    app,
    // dsn: "https://e925c09dca4548e7a8bae2fe2dcadb55@o294738.ingest.sentry.io/6182912",
    integrations: [
        new BrowserTracing({
            routingInstrumentation: Sentry.vueRouterInstrumentation(router),
            tracingOrigins: ["localhost", "www.lidiaelorenzo.it", /^\//],
        }),
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
});

// new Vue({
//     router,
//     render: h => h(App)
// }).$mount('#app')

app.mount('#app')