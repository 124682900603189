
import {Options, Vue} from "vue-class-component";

@Options({
  props: {
    size: Number
  }
})
export default class Footer extends Vue {
}
