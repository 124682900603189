
import {Options, Vue} from "vue-class-component";
import ExternalLink from "@/components/icons/ExternalLink.vue";
import Close from "@/components/icons/Close.vue";

@Options({
  components: {
    Close, ExternalLink
  },
  props: {
    show: Boolean
  }
})
export default class LocationMapModal extends Vue {
  close() {
    this.$emit('close')
  }
}
