import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "mr-4 mb-4 text-center border-4 rounded bg-yellow-50 border-yellow-50 p-2 w-32" }
const _hoisted_2 = { class: "text-5xl bg-olive-50 text-white rounded px-2 py-4 mb-3" }
const _hoisted_3 = { class: "text-lg" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.value), 1),
    _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.label), 1)
  ]))
}