
import {Options, Vue} from 'vue-class-component'
import LocationImageSlider from "@/components/LocationImageSlider.vue"
import ExternalLink from "@/components/icons/ExternalLink.vue"
import LocationMapLink from "@/components/LocationMapLink.vue";

@Options({
  components: {
    LocationMapLink, ExternalLink, LocationImageSlider
  }
})
export default class Location extends Vue {
}
