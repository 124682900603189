
import {Options, Vue} from "vue-class-component";

@Options({
  props: {
    value: Number,
    label: String
  }
})
export default class CountdownItem extends Vue {
}
