import {nextTick} from 'vue'
import {
    createRouter,
    createWebHistory,
    RouteLocationNormalized,
    RouteRecordRaw,
    RouterOptions
} from 'vue-router'
import Home from '../views/Home.vue'

const routes: RouteRecordRaw[] = [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    // {
    //     path: '/rsvp',
    //     redirect: '/'
    // },
    // {
    //     path: '/conferma',
    //     name: 'Conferma',
    //     component: () => import('../views/Rsvp.vue')
    // },
    // {
    //     path: '/come-raggiungerlo',
    //     name: 'Come raggiungerlo',
    //     component: () => import('../views/HowToReach.vue')
    // },
    // {
    //     path: '/pernottamento',
    //     name: 'Pernottamento',
    //     component: () => import('../views/Pernottamento.vue')
    // }
]

const routerOptions: RouterOptions = {
    history: createWebHistory(),
    routes
}

const router = createRouter(routerOptions)

const defaultTitle = 'Lidia & Lorenzo';
router.afterEach((to: RouteLocationNormalized) => {
    // Use next tick to handle router history correctly
    // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
    nextTick(() => {
        const s = to.name as String
        document.title = s + ' | ' + defaultTitle || defaultTitle;
    });
});

export default router
