import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "relative py-3 px-6 mt-6 sm:max-w-6xl sm:mx-auto" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.messages, (message, i) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "p-4 mb-4 text-sm text-green-700 bg-green-100 rounded-lg dark:bg-green-200 dark:text-green-800",
        role: "alert",
        key: i
      }, _toDisplayString(message), 1))
    }), 128)),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.errors, (error, i) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "p-4 mb-4 text-sm text-red-700 bg-red-100 rounded-lg dark:bg-red-200 dark:text-red-800",
        role: "alert",
        key: i
      }, _toDisplayString(error), 1))
    }), 128))
  ]))
}